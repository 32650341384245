
export default function WhatsappIcon({ }) {

    return (
        <div className="fixedIcons">
            <a target="_blank" href="https://api.whatsapp.com/send/?phone=972522359800&text=%D7%94%D7%99%D7%99+%D7%91%D7%A7%D7%A9%D7%A8+%D7%9C%D7%A1%D7%95%D7%95%D7%99%D7%98%D7%94"><svg viewBox="0 0 32 32" className="whatsapp-ico"><path d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fillRule="evenodd"></path></svg></a>

            <a target="_blank" href="https://waze.com/ul?ll=32.498626,35.500044&navigate=yes"><svg xmlns="http://www.w3.org/2000/svg" className="waze-ico" viewBox="-1.8 -1.2 20 19" id="waze"><path fill="#ffffff" d="M9 13.5c3.59 0 6.5-2.686 6.5-6s-2.91-6-6.5-6-6.5 2.686-6.5 6c0 0 .469 3-2 3 0 2.5 4.5 3 8.5 3"></path><path fill="#455A64" d="M9 14c-6.056 0-9-1.145-9-3.5a.5.5 0 0 1 .5-.5c.496 0 .858-.142 1.107-.432.584-.683.401-1.978.399-1.991C2 3.916 5.14 1 9 1c3.859 0 7 2.916 7 6.5S12.859 14 9 14zm-7.924-3.052C1.552 12.293 4.268 13 9 13c3.309 0 6-2.468 6-5.5C15 4.467 12.309 2 9 2S3 4.467 3 7.5c.006 0 .255 1.681-.633 2.718a2.179 2.179 0 0 1-1.291.73z"></path><g fill="#37474F"><circle cx="11" cy="14" r="2"></circle><circle cx="5" cy="14" r="2"></circle></g><path fill="#455A64" d="M7.02 6.5c-.276 0-.505-.224-.505-.5s.219-.5.495-.5h.01a.5.5 0 0 1 0 1zM11.02 6.5c-.275 0-.505-.224-.505-.5s.219-.5.495-.5h.01a.5.5 0 0 1 0 1zM9 11a3.046 3.046 0 0 1-2.988-2.39.501.501 0 0 1 .976-.22A2.051 2.051 0 0 0 9 10a2.05 2.05 0 0 0 2.012-1.61.5.5 0 0 1 .977.22A3.047 3.047 0 0 1 9 11z"></path></svg></a>

            

        </div>
    );
};


